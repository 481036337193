.Fronter {
    color: white;
}

.Fronter .name {
    font-size: 4rem;
}

.Fronter .reporter {
    font-size: 1.25rem;
    font-style: italic;
}

.Fronter .datetime {
    font-size: 1.25rem;
    font-style: italic;
}